<template>
  <div class="form">
    <div class="form-title">
      <span>{{$t("form_Q11")}}</span>
      <span class="danger-text">{{$t("form_require")}}</span>
    </div>

    <section class="form-container">

      <!--form1-->
      <section>
        <div class="form-sub-title">
          <span>{{$t("form_Q11_form1")}}</span>
          <span class="danger-text">{{$t("form_require")}}</span>
        </div>

        <div>
          <div class="form_desc mb-1">
            {{$t("form_Q11_desc")}}
          </div>

          <v-text-field
            outlined
            dense
            :label="$t('form_placeholder')"
            :rules="rules.q11"
            v-model="q11"
            counter="255"
            @change="setData_q11()"
          ></v-text-field>
        </div>
      </section>

      <!--form2-->
      <section>
        <div class="form-sub-title" style="margin-top: 50px">
          <span>{{$t("form_Q11_form2")}}</span>
          <span class="danger-text">{{$t("form_require")}}</span>
        </div>

        <div>
          <v-textarea
            rows="5"
            dense
            outlined
            :label="$t('form_placeholder')"
            :rules="rules.q12"
            v-model="q12"
            counter="500"
            @change="setData_q12()"
          ></v-textarea>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Form11',
  data() {
    return {
      q11: "",
      q12: "",
      rules: {
        q11: [
          v => !!v || '入力してください',
          v => (v && v.length <= 255) || '255文字以内で入力してください',
        ],
        q12: [
          v => !!v || '入力してください',
          v => (v && v.length <= 500) || '500文字以内で入力してください'
        ]
      }
    }
  },
  mounted() {
    let sessionItem_q11 = sessionStorage.getItem('q11')
    let sessionItem_q12 = sessionStorage.getItem('q12')

    if(sessionItem_q11) {
      this.q11 = sessionItem_q11
    }

    if(sessionItem_q12) {
      this.q12 = sessionItem_q12
    }
  },
  methods: {
    setData_q11(){
      if(this.q11) {
        sessionStorage.setItem('q11', this.q11)
      } else {
        sessionStorage.setItem('q11', '')
      }
    },
    setData_q12(){
      if(this.q12) {
        sessionStorage.setItem('q12', this.q12)
      } else {
        sessionStorage.setItem('q12', '')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 20px;

  .form-title {
    font-size: max(16px, 1.8vw);
    font-weight: bold;
    color: #132d7a;
    text-align: center;
  }

  .form-container {
    margin: 0 15%;
    display: flex;
    flex-direction: column;
    height: 90%;
    justify-content: center;

    .form-sub-title {
      font-size: max(14px, 1.2vw);
      font-weight: bold;
      margin-bottom: 20px;
    }

    .form_desc {
      font-size: max(12px, 1.3vw);
      color: gray;
    }
  }

}

</style>